import './App.css';

function MyButton() {
  return (
    <button>I'm a button</button>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="IMG_0949.JPG" alt="Bonded Pair" />
        <img src="IMG_5651.PNG" alt="Scrunge" />
        <img src="IMG_2916.JPG" alt="Folded Ear" />
        <img src="IMG_2585sml.JPG" alt="Peeping Tom" />
        <p>
          Main!!
        </p>
      </header>
        <h1>Welcome to my app</h1>
        <MyButton />
      </div>
  );
}
export default App;
